import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-scottsdale-arizona.png'
import image0 from "../../images/cities/scale-model-of-arizona-rafting-by-wilderness-aware-in-scottsdale-arizona.png"
import image1 from "../../images/cities/scale-model-of-frank-lloyd-wright-spire-in-scottsdale-arizona.png"
import image2 from "../../images/cities/scale-model-of-sunstate-helicopter-tours-in-scottsdale-arizona.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Scottsdale'
            state='Arizona'
            image={image}
            lat='33.4941704'
            lon='-111.9260519'
            attractions={ [{"name": "Arizona Rafting by Wilderness Aware", "vicinity": "7360 E Acoma Dr #4, Scottsdale", "types": ["point_of_interest", "establishment"], "lat": 33.618721, "lng": -111.92253299999999}, {"name": "Frank Lloyd Wright Spire", "vicinity": "7207 E Frank Lloyd Wright Blvd, Scottsdale", "types": ["point_of_interest", "establishment"], "lat": 33.6376676, "lng": -111.9248925}, {"name": "SunState Helicopter Tours", "vicinity": "7430 E Butherus Dr Suite G, Scottsdale", "types": ["travel_agency", "university", "point_of_interest", "establishment"], "lat": 33.6231573, "lng": -111.91857390000001}] }
            attractionImages={ {"Arizona Rafting by Wilderness Aware":image0,"Frank Lloyd Wright Spire":image1,"SunState Helicopter Tours":image2,} }
       />);
  }
}